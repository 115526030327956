<template>
<div class="wrapper">
  <side-bar>
    <template slot="links">
      <div class="" v-for="item in listmenu" v-if="item.enable || permis">
        <label class="labelHead" vif>{{item.name}}</label>
          <sidebar-link v-for="value in item.data" :to="value.link" :name="value.name" :icon="value.icon" v-if="value.enable && permission(value.fullname) || permis" />
        <hr>
      </div>
      <br/>

    </template>
  </side-bar>
  <div class="main-panel">
    <top-navbar></top-navbar>

    <dashboard-content @click.native="toggleSidebar">

    </dashboard-content>

    <content-footer></content-footer>
  </div>
</div>
</template>
<style lang="scss">
</style>
<script>
import Config from "@/config.js";
import utils from '@/utils';
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  data() {
    return {
      listmenu:[],
      permis: utils.permission(`GOD`)
    }
  },
  methods: {
    permission(name){
      return utils.permission(`${name}_VIEW`)
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  mounted(){
    this.listmenu = Config.memnu;
     //console.log('Config',Config.memnu);
  },
};
</script>
<style media="screen">
.sidebar .nav li>a,
.off-canvas-sidebar .nav li>a {
  margin: 0px 15px 0 !important;
  padding: 5px 8px !important;
}
hr{
  margin-top: 5px  !important;
  margin-bottom: 5px  !important;
}
.labelHead{
  color: #FFF;
  font-weight: bold;
  margin-left: 20px;
}

</style>
