<template>
  <div :class="{'tooltipS': title}" style="float: left; "> 
    <span class="tooltiptext" v-show="title">{{title}}</span>
    <component
      ref="element"
      :is="tag"
      :type="tag === 'button' ? nativeType : ''"
      :disabled="disabled || loading"
      @click="handleClick"
      class="btn "
      style="width: 100%;"
      :class="[
        {'btn-round': round},
        {'btn-block': block},
        {'btn-icon btn-fab': icon},
        {[`btn-${type}`]: type},
        {[`btn-${size}`]: size},
        {'btn-simple': simple},
        {'btn-link': link},
        {'disabled': disabled && tag !== 'button'}
      ]">
      <slot name="loading">
        <i v-if="loading" class="fas fa-spinner fa-spin"></i> 
      </slot>
      <slot></slot>
    </component>
  </div>
</template>
<script>
export default {
  name: "base-button",
  props: {
    tag: {
      type: String,
      default: "button",
      description: "Button html tag"
    },
    round: Boolean,
    icon: Boolean,
    block: Boolean,
    loading: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: "default",
      description: "Button type (primary|secondary|danger etc)"
    },
    // tooltip: {
    //   type: Object,
    //   default: "",
    //   description: "{ content: 'TEXT'}"
    // },
    title: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type"
    },
    nativeType: {
      type: String,
      default: "button",
      description: "Button native type (e.g button, input etc)"
    },
    size: {
      type: String,
      default: "",
      description: "Button size (sm|lg)"
    },
    simple: {
      type: Boolean,
      description: "Whether button is simple (outlined)"
    },
    link: {
      type: Boolean,
      description: "Whether button is a link (no borders or background)"
    },
  },
  data() {
    return {
       
    }
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
    // tooltipOver(e){
    //   if(this.title){
    //     this.tooltipAc = true;
    //     var top = e.screenY-100 //this.$refs.element.offsetTop + e.screenY;
    //     var left = e.screenX+100 //this.$refs.element.offsetLeft + e.screenX;
    //     this.$refs.tooltipPopup.style.top = `${top}px`;
    //     this.$refs.tooltipPopup.style.left = `${left}px`;
    //   }
    // },
    // tooltipOut(e){
    //   if(this.title){
    //     this.tooltipAc = false;
    //   }
    // }
  }
};
</script>
<style>
</style>
