<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="column in columns" :key="column">{{  formatColum(column) }}</th>
        <th>Event</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td v-for="(column, index) in columns" :key="index"   v-if="hasValue(item, column)" v-html="itemValue(item, column)"> </td>
        <td class="td-actions">
          <base-button type="info" title="ดูข้อมูล" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'info','item':item})">
            <i class="tim-icons icon-paper"></i>
          </base-button>
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
  import utils from '@/utils';
  export default {
    name: 'base-table',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      formatColum(column){
        let FormatOld = {
          "platformCode" : "ค่าย",
          "gameImg" : "เกม",
          "type" : "กลุ่ม",
          "amount" : "แทง-ผลลัพท์",
          "before" : "ยอดก่อนหน้า",
          "after" : "ยอดล่าสุด",
          "betTime" : "เวลาแทง",
          "roundId" : "รอบ",
          "gameId" : "ไอดีเกม",
          "created_at" : "วันที่",
        }
        return FormatOld[column];
        // if(column == 'platformCode'){
        //   return 'platform';
        // }else if(column == 'debit_betTime'){
        //   return 'betTime'
        // }else if(column == 'credit_betTime'){
        //   return 'betTime'
        // }else if(column == 'created_at' || column ==  'updated_at'){
        //   return 'created'
        // }else{
        //   return column;
        // }
      },
      modalsUser(item){
        this.$emit("withd-modals",item);
      },
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        if(column == 'created_at' || column ==  'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else if(column == 'betTime'){ 
          let IntTime =  parseInt(item[column]);
          if(IntTime.toString().length < 11){
            return utils.forMatDateTimes(IntTime*1000); // parseInt
          }else{
            return utils.forMatDateTimes(IntTime); // parseInt
          }
          // return IntTime.toString().length;
        }else if(column == 'platformCode'){
          return `<img src="/img/icons_games/${item[column]}.png" style="width: 80px;"/>`
          // return `${item[column]}`s
        }else if(column == 'gameImg'){
          if(item[column] == '/'){
            // return `<div><img src="https://images.sharkbet777.com/typegame/${item['platformCode']}.png" style="width: 80px;"/><div/> `
            return item['platformCode'];
          }else{
            return item['gameName'];
            //return `<div style="width: 50px; height: 50px; overflow: hidden;"><img src="https://images.sharkbet777.com/iconsGame/${item['platformCode']}/${item[column]}.png" style="width: 80px;"/><div/> `;
          }
          // return item.gameName;
        }else if(column == 'amount'){
          if(item['method'] == 'debit'){
            if(item[column] == 0){
              return `<span style="color: #00e7ff;">ฟรีสปิน</span>`
            }else{
              return `<span style="color: #F00;">-${item[column]}</span>`
            }
          }else{
            if(item[column] > 0){
              return `<span style="color: #38ff00;">+${item[column]}</span>`
            }else{
              return `<span style="color: #ffd100;">${item[column]}</span>`
            }
          }
        }else if(column == 'before'){
          return `<span style="color: #ff4ae9;">${item[column]}</span>`
        }else if(column == 'after'){
          return `<span style="color: #5a6eed;">${item[column]}</span>`
        }else{
          return (item[column])?item[column]:'-';
        }

      }
    }
  };
</script>
<style>
</style>
