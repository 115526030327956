<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="column in columns" :key="column">{{columnFormat(column)}}</th>
        <th>เปิด/ปิด (การมองเห็นบนเว็บ)</th>
        <th>เปิด/ปิด (เปิด-ปิด การรับข้อมูล)</th>
        <th>Event</th>
        <th>เวลาเปิดใช้งาน</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td v-for="(column, index) in columns" :key="index"   v-if="hasValue(item, column)" v-html="itemValue(item, column)">   {{ itemValue(item, column) || '-'}} </td>
        <td>
          <label class="switch" v-if="item.typesys != 'withdraw'">
            <input v-model="item.web" @change="ChangeStats('web',index,item)" type="checkbox" checked>
            <span class="slider round"></span>
          </label>
        </td>
        <td>
          <label class="switch"> 
            <input v-model="item.sys" @change="ChangeStats('sys',index,item)" type="checkbox" checked>
            <span class="slider round"></span>
          </label>
        </td>
        <td>  
          <base-button type="info" title="ดูข้อมูล" style="margin-right: 5px;" icon size="sm" @click="modalsEdit({'eve':'edit','data':item})">
            <i class="tim-icons icon-paper"></i>
          </base-button>
          <base-button type="warning" title="ตรวจสอบยอด" style="margin-right: 5px;" icon size="sm" @click="recordbank(item.number)">
            <i class="tim-icons icon-calendar-60"></i>
          </base-button>
        </td>
        <td>{{ item.opentime }}</td>

      </slot>
    </tr>
    </tbody>
  </table>
</template>

<script>
///////
import utils from '@/utils';
  export default {
    name: 'base-table-controlbank',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      columnFormat(column){
        let columnName = {
          "type":"ไอคอน",
          "typesys":"สถานะ",
          "number":"เลขบัญชี",
          "name":"ชื่อบัญชี"
        }
        return columnName[column];
      },
      modalsEdit(item){
        this.$emit("Editupdate",item);
      },
      ChangeStats(eve,index,item){
        this.$emit("ChangeStats",{index:index,eve:eve,_id:item._id,agentId:item.agentId,web:item.web,sys:item.sys,number:item.number,name:item.name,nameBank:item.nameBank,proce:item.process,opentime:item.opentime});
        if(item.sys == false){
          this.$emit("openTime",{key:index,eve:eve,status:true});
        }else{
          this.$emit("openTime",{key:index,eve:eve,status:false});
        }
      },
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {

        if(column == 'created_at' || column ==  'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else if(column == 'typesys'){
          if(item[column] == 'deposit'){
            return `<span style="color: #00ff14;">ระบบฝาก</span>`;
          }else{
            return '<span style="color: #fd00ff;">ระบบถอน</span>';
          }
        }else if(column == 'type'){
          return `<img style="width: 50px;" src="img/icons_sys/${item[column]}.jpg"/>`
        }else{
          return item[column];
        }
        // if(this.isValidDate(new Date(item[column]))){
        //   return utils.forMatDateTimes(new Date(item[column]));
        // }else{
        //   return item[column];
        // }

      },
      recordbank(NumberB){
        this.$router.push(`/recordbank?s=${NumberB}`);
      }
    }
  };
</script>
<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
