 <template>
  <div class="form-group">
    <label v-if="label" class="control-label">{{label}}</label>
    <hr>
    <div v-on="listeners"  ref="textarea" class="textarea" contenteditable spellcheck="false"> {{value}} </div>
  </div>
</template>
<script>
  export default {
    name: 'contenteditable',
    model: {
      prop: 'value',
      event: 'input'
    },
    props: {
      value: {
        type: String, // striped | hover
        default: "",
        description: "text"
      },
      label: {
        type: String,
        description: "Input label"
      },
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
          blur: this.onBlur,
          focus: this.onFocus
        }
      }
    },
    methods: {
      validate() {
        this.$refs.textarea.validate();
      },
      onInput(evt) {
        this.$emit('input', this.$refs.textarea.innerText)
      },
      onFocus() {
        this.focused = true;
      },
      onBlur() {
        this.focused = false;
      }

    }
  }
</script>
<style>

</style>
