export default [
    /////////////// DASHBOARD
   {fullname: "REPORTS", name:'รายงาน', enable:true, data:[
     {fullname:"DASHBOARD", permission:[
       {name:'ดู',value:'DASHBOARD_VIEW'}
     ] ,link:"/dashboard", name:"แผงควบคุม", icon:"tim-icons icon-chart-pie-36", enable:true},
     {fullname:"RECORE", permission:[
       {name:'ดู',value:'DASHBOARD_VIEW'}
     ] ,link:"/record", name:"ประวัติย้อนหลัง", icon:"tim-icons icon-chart-pie-36", enable:true},
     {fullname:"RECOREBANK", permission:[
       {name:'ดู',value:'DASHBOARD_VIEW'}
     ] ,link:"/recordbank", name:"ประวัติธนคารฝาก", icon:"tim-icons icon-chart-pie-36", enable:true},
     // {fullname:"WINLOSE", permission:[
     //   {name:'ดู',value:'DASHBOARD_VIEW'}
     // ] ,link:"/winlose", name:"แพ้-ชนะ", icon:"tim-icons icon-chart-pie-36", enable:false}
   ]},
   {fullname: "MEMBERS", name:'จัดการสมาชิก', enable:true, data:[
     ///// COSTOMER
     {fullname:"COSTOMER", permission:[
       {name:'ดู',value:'COSTOMER_VIEW'},
       {name:'แก้ไข',value:'COSTOMER_EDIT'},
       {name:'บ๊อค',value:'COSTOMER_BLOCK'}
     ] ,link:"/costomer", name:"ข้อมูลสมาชิก", icon:"tim-icons icon-controller", enable:true},
     ///// MODLIST
     {fullname:"MODLIST", permission:[
       {name:'ดู',value:'MODLIST_VIEW'},
       {name:'แก้ไข',value:'MODLIST_EDIT'},
       {name:'ลบ',value:'MODLIST_DEL'},
       {name:'เพิ่ม',value:'MODLIST_ADD'}
     ] ,link:"/modlist", name:"ข้อมูลผู้ดูแล", icon:"tim-icons icon-single-02", enable:true}
   ]}, 
  //  {fullname: "GAMES", name:'รายงานเกม', enable:true, data:[
  //    ///// COSTOMER
  //    {fullname:"GAMESLOG", permission:[
  //      {name:'ดู',value:'GAMESLOG_VIEW'},
  //    ] ,link:"/gamelog", name:"รายการเล่นเกม", icon:"tim-icons icon-controller", enable:true},
  //    // {fullname:"GAMESOPTION", permission:[
  //    //   {name:'ดู',value:'GAMES_OPTION_VIEW'},
  //    //   {name:'แก้ไข',value:'GAMES_OPTION_EDIT'}
  //    // ] ,link:"/gamesoption", name:"ออฟชั่นเกม",icon:"tim-icons icon-settings-gear-63", enable:true},
  //    ///// MODLIST
  //    // {fullname:"MODLIST", permission:[
  //    //   {name:'ดู',value:'MODLIST_VIEW'},
  //    //   {name:'แก้ไข',value:'MODLIST_EDIT'},
  //    //   {name:'เพิ่ม',value:'MODLIST_ADD'}
  //    // ] ,link:"/modlist", name:"ข้อมูลผู้ดูแล", icon:"tim-icons icon-single-02", enable:true}
  //  ]},
   {fullname: "REQUIRE", name:'ฝาก / ถอน / ใช้โปร', enable:true, data:[

     ///// LISTWITHDRAW
     {fullname:"LISTWITHDRAW", permission:[
       {name:'ดู',value:'LISTWITHDRAW_VIEW'},
       {name:'แก้ไข',value:'LISTWITHDRAW_EDIT'}
     ] ,link:"/listwithdraw", name:"รายการถอนเงิน", icon:"tim-icons icon-coins", enable:true},
     ///// LISTTRANSFERFAIL
     {fullname:"LISTGETMONEY", permission:[
       {name:'ดู',value:'LISTGETMONEY_VIEW'},
       {name:'เพิ่ม',value:'LISTGETMONEY_ADD'}
     ] ,link:"/listGetMoney", name:"เพิ่มเงินให้ลูกค้า", icon:"tim-icons icon-wallet-43", enable:true},
     ///// LISTTRANSFERFAIL
     {fullname:"LISTTRANSFERFAIL", permission:[
       {name:'ดู',value:'LISTTRANSFERFAIL_VIEW'},
       {name:'แก้ไข',value:'LISTTRANSFERFAIL_EDIT'},
       {name:'ลบ',value:'LISTTRANSFERFAIL_DEL'}
     ] ,link:"/listTransferFail", name:"รายงานฝากไม่สำเร็จ", icon:"tim-icons icon-alert-circle-exc", enable:true},
     ///// LISTTRANSFESUCCESS
     {fullname:"LISTTRANSFERSUCCESS", permission:[
       {name:'ดู',value:'LISTTRANSFERSUCCESS_VIEW'},
       {name:'ลบ',value:'LISTTRANSFERFAIL_DEL'}
     ] ,link:"/listTransferSuccess", name:"รายงานฝากสำเร็จ", icon:"tim-icons icon-money-coins", enable:true},
     /////
     {fullname:"LISTUSERPROMOTION", permission:[
      {name:'ดู',value:'LISTUSERPROMOTION_VIEW'} 
    ] ,link:"/listTransferCredit", name:"รายการใช้โปรโมชั่น", icon:"tim-icons icon-bag-16", enable:true},
     ///// TRUEWALLET
    //  {fullname:"TRUEWALLET", permission:[
    //    {name:'ดู',value:'TRUEWALLET_VIEW'},
    //    {name:'ลบ',value:'TRUEWALLET_DEL'}
    //  ] ,link:"/TrueWallet", name:"รายการทรูวอเรท", icon:"tim-icons icon-tablet-2", enable:true},
    //  ///// SCBBANK
    //  {fullname:"SCBBANK", permission:[
    //    {name:'ดู',value:'SCBBANK_VIEW'},
    //    {name:'ลบ',value:'SCBBANK_DEL'}
    //  ] ,link:"/Scbbank", name:"รายการธนาคาร", icon:"tim-icons icon-bank", enable:true},
     ///// COSTUMNER WITHDRAW
    //  {fullname:"COSTUMNERWITHDRAW", permission:[
    //    {name:'ดู',value:'COSTUMNERWITHDRAW_VIEW'},
    //    {name:'แก้ไข',value:'COSTUMNERWITHDRAW_EDIT'},
    //  ] ,link:"/listCostummerWithdraw", name:"ลูกค้าแจ้งฝาก", icon:"tim-icons icon-paper", enable:false},

     // ///// memner loss
     // {fullname:"MEMBERLOSS", permission:[
     //   {name:'ดู',value:'LOSS_VIEW'}
     // ] ,link:"/memberloss", name:"รายการคืนยอดเสีย", icon:"tim-icons icon-trophy", enable:true},
     ///// sms
    //  {fullname:"SMSSCB", permission:[
    //    {name:'ดู',value:'SMSSCB_VIEW'}
    //  ] ,link:"/smsScb", name:"รายการข้อความ", icon:"tim-icons icon-chat-33", enable:true}
   ]},
  //  {fullname: "CONFIRM", name:'คอนเฟิร์ม', enable:true, data:[
  //    {fullname:"CONFIRMPAGE", permission:[
  //      {name:'ดู',value:'CONFIRMPAGE_VIEW'},
  //      {name:'คอนเฟิร์ม',value:'CONFIRMPAGE_EDIT'}
  //    ] ,link:"/confirmpage", name:"รายการรออนุมัติ", icon:"tim-icons icon-notes", enable:true},
  //  ]},
   //// กิจกรรม
   {fullname: "EVENT", name:'กิจกรรม', enable:true, data:[
      ///// friendspoint
      {fullname:"FRIENDSPOINT", permission:[
        {name:'ดู',value:'FRIENDSPOINT_VIEW'}
      ] ,link:"/friendspoint", name:"ชวนเพื่อน", icon:"tim-icons icon-satisfied", enable:true},
      ///// Free Spin
      {fullname:"FREESPIN", permission:[
        {name:'ดู',value:'FREESPIN_VIEW'}
      ] ,link:"/freespin", name:"วงล้อหรรษาพาโชค", icon:"tim-icons icon-spaceship", enable:true},
  ]},
   {fullname: "CONFIG", name:'คอนฟิก', enable:true, data:[
     /////
     {fullname:"CONFIGBANK", permission:[
       {name:'ดู',value:'CONFIGBANK_VIEW'},
       {name:'เพิ่ม',value:'CONFIGBANK_ADD'},
       {name:'แก้ไข',value:'CONFIGBANK_EDIT'},
       {name:'รีระบบ',value:'CONFIGBANK_RESET'}
       // {name:'เพิ่ม',value:'CONFIGBANK_ADD'}
     ] ,link:"/configbank", name:"จัดการธนาคาร", icon:"tim-icons icon-settings-gear-63", enable:true},
     /////
     {fullname:"PROMOTION", permission:[
       {name:'ดู',value:'PROMOTION_VIEW'},
       {name:'แก้ไข',value:'PROMOTION_EDIT'},
       {name:'เพิ่ม',value:'PROMOTION_ADD'}
     ] ,link:"/promotion", name:"จัดการโปรโมชั่น", icon:"tim-icons icon-settings-gear-63", enable:true},

    //  {fullname:"LISTGAMES", permission:[
    //    {name:'ดู',value:'LISTGAMES_VIEW'},
    //    {name:'แก้ไข',value:'LISTGAMES_EDIT'}
    //  ] ,link:"/controlgame", name:"รายการเกม", icon:"tim-icons icon-settings-gear-63", enable:true},

     {fullname:"AFLINK", permission:[
       {name:'ดู',value:'AFLINK_VIEW'},
       {name:'แก้ไข',value:'AFLINK_EDIT'}
     ] ,link:"/AFLink", name:"รายการลิ้งค์ AF", icon:"tim-icons icon-settings-gear-63", enable:true},

    //  {fullname:"TAGGAME", permission:[
    //    {name:'ดู',value:'TAGGAME_VIEW'},
    //    {name:'แก้ไข',value:'TAGGAME_EDIT'}
    //  ] ,link:"/taggame", name:"คืนยอดเสีย", icon:"tim-icons icon-settings-gear-63", enable:true}
   ]},
   ///// จัดการเว็บเพจ
   {fullname: "CONFIGPAGE", name:'จัดการเว็บเพจ', enable:true, data:[
     /////
     {fullname:"LOBBY", permission:[
       {name:'ดู',value:'MASTER'},
       {name:'แก้ไข',value:'MASTER'}
       // {name:'เพิ่ม',value:'CONFIGBANK_ADD'}
     ] ,link:"/agentinfo", name:"Lobby และ Agent", icon:"tim-icons icon-html5", enable:true},

     /////
     // {fullname:"IMAGEBIGSLIDE", permission:[
     //   {name:'ดู',value:'IMAGEBIGSLIDE_VIEW'},
     //   {name:'แก้ไข',value:'IMAGEBIGSLIDE_EDIT'},
     //   {name:'เพิ่ม',value:'IMAGEBIGSLIDE_ADD'}
     // ] ,link:"/imageSlide", name:"จัดการสไลด์", icon:"tim-icons icon-html5", enable:false},
     // /////
     // {fullname:"PROMOIMAGE", permission:[
     //   {name:'ดู',value:'PROMOIMAGE_VIEW'},
     //   {name:'แก้ไข',value:'PROMOIMAGE_EDIT'},
     //   {name:'เพิ่ม',value:'PROMOIMAGE_ADD'}
     // ] ,link:"/imagePromotion", name:"จัดการรูปโปรโมชั่น", icon:"tim-icons icon-html5", enable:false},
     // /////
     // {fullname:"JACKPOT", permission:[
     //   {name:'ดู',value:'JACKPOT_VIEW'},
     //   {name:'แก้ไข',value:'JACKPOT_EDIT'},
     //   {name:'เพิ่ม',value:'JACKPOT_ADD'}
     // ] ,link:"/imageJackpot", name:"จัดการรูปบิลใบเสร็จ", icon:"tim-icons icon-html5", enable:false},
   ]},
//    {fullname: "CONFISMS", name:'ส่ง SMS', enable:true, data:[
//      /////
//      {fullname:"SENDSMS", permission:[
//        {name:'ดู',value:'SENDSMS_VIEW'},
//        {name:'แก้ไข',value:'SENDSMS_EDIT'}
//      ] ,link:"/listMemberSMS", name:"จัดการเบอร์", icon:"tim-icons icon-mobile", enable:true},

//    ]},
   {fullname: "SYSTEM", name:'ระบบ', enable:true, data:[
     ///// LOGS
     {fullname:"LOGS", permission:[
       {name:'ดู',value:'LOGS_VIEW'}
     ] ,link:"/logs", name:"LOGS", icon:"tim-icons icon-watch-time", enable:true},
     {fullname:"HELP", permission:[
      {name:'ดู',value:'HELP_VIEW'}
    ] ,link:"/help", name:"ช่วยเหลือ", icon:"tim-icons icon-bulb-63", enable:true}
   ]}
 ]