import { render, staticRenderFns } from "./BaseTableSpin.vue?vue&type=template&id=d05798c2&scoped=true"
import script from "./BaseTableSpin.vue?vue&type=script&lang=js"
export * from "./BaseTableSpin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d05798c2",
  null
  
)

export default component.exports