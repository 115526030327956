<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="column in columns" :key="column">{{formatColumn(column)}}</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td v-for="(column, index) in columns" :key="index"   v-if="hasValue(item, column) && column != 'event'" v-html="itemValue(item, column)"></td>
        <td v-for="(column, index) in columns" :key="index"   v-if="hasValue(item, column) && column == 'event'">
          <base-button type="warning" title="ดูรายละเอียด" style="margin-right: 5px;" icon size="sm" @click="modalsUser(item)">
            <i class="tim-icons icon-tap-02"></i>
          </base-button>
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
  import utils from '@/utils';
  export default {
    name: 'base-table',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      formatColumn(column){
        let newColumn = {
          "action":"หมวด",
          "commit":"คำรอง",
          "admin":"ผู้ดูแล",
          "owner":"สมาชิก",
          "event":"รายละเอียด"
        }
        return newColumn[column];
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        if(column == 'created_at' || column ==  'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else if(column == 'action'){
          if(item[column].title == 'Withdraw'){
            return `<span class="tale-status-wait"> <i class="tim-icons  icon-coins"/> ถอน </span>`;
          }else if(item[column].title == 'GetMoney'){
            return `<span class="tale-status-active"><i class="tim-icons  icon-bag-16"/> เพิ่มกระเป๋า </span>`;
          }else if(item[column].title == 'Costomer'){
            return `<span class="tale-status-reject"><i class="tim-icons  icon-single-02"/> แก้ไขสมาชิก </span>`;
          }else{
            return `<span class="tale-status-wait"><i class="tim-icons  icon-alert-circle-exc"/> ไม่มีหัวข้อ </span>`;
          }
        }else{
          return item[column] || '=';
        }
        // if(this.isValidDate(new Date(item[column]))){
        //   return utils.forMatDateTimes(new Date(item[column]));
        // }else{
        //   return item[column];
        // } 
      },
      modalsUser(item){
        this.$emit("modals",item);
      }
    }
  };
</script>
<style>
</style>
