<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th>ICON</th>
        <th v-for="column in columns" :key="column">{{column}}</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td><img class="icons-sys" :src="`img/icons_sys/${item.type}.jpg`" /></td>
        <td v-for="(column, index) in columns" :key="index" v-if="itemValue(item, column) != 'button'" v-html="itemValue(item, column)"></td>
        <td v-for="(column, indx) in columns" :key="indx" v-if="itemValue(item, column) == 'button'">
          <base-button v-if="permis.delete" type="danger" style="margin: 0px 5px;"  size="sm" icon @click="setValue({index:index,eve:'delete',data:item})">
            <i class="tim-icons icon-trash-simple"></i>
          </base-button>
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>

<script>
  import config from '../config';
  import utils from '@/utils'; 
  export default {
    name: 'base-table-true-wallet',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      permis: {
        type: Object
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      setValue(item) {
        this.$emit("user-modals",item)
      },
      itemValue(item, column) {
        if(column == 'created_at' || column ==  'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else if(column == 'active'){
          return 'button';
        }else if(column == 'owner'){
          return item[column].tel;
        }else{
          return item[column];
        }
      }
    }
  };
</script>
<style>
.icons-sys{
  width: 34px;
  border-radius: 5px;
}
</style>
