var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{'tooltipS': _vm.title},staticStyle:{"float":"left"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.title),expression:"title"}],staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.title))]),_c(_vm.tag,{ref:"element",tag:"component",staticClass:"btn ",class:[
      {'btn-round': _vm.round},
      {'btn-block': _vm.block},
      {'btn-icon btn-fab': _vm.icon},
      ( _obj = {}, _obj[("btn-" + _vm.type)] = _vm.type, _obj ),
      ( _obj$1 = {}, _obj$1[("btn-" + _vm.size)] = _vm.size, _obj$1 ),
      {'btn-simple': _vm.simple},
      {'btn-link': _vm.link},
      {'disabled': _vm.disabled && _vm.tag !== 'button'}
    ],staticStyle:{"width":"100%"},attrs:{"type":_vm.tag === 'button' ? _vm.nativeType : '',"disabled":_vm.disabled || _vm.loading},on:{"click":_vm.handleClick}},[_vm._t("loading",[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()]),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }