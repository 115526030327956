<template>
  <table class="table tablesorter" :class="tableClass">
    <thead>
      <tr class="recordTab">
        <slot name="columns">
          <th class="text-center" style="background: #00bcd4 !important;">ทั้งหมด : {{sum.all | forMatNymber}}  </th>
          <th class="text-center" style="background: #f44336 !important;">รอลงทะเบียน : {{sum.nosact | forMatNymber}} </th>
          <th class="text-center" style="background: #9c27b0 !important;">ลงทะเบียนแล้ว : {{sum.act | forMatNymber}} </th>
          <th class="text-center" style="background: #4caf50 !important;">
            <ul class="listCodePhone">
              <li>รหัส 09 มี {{sum.type.okao | forMatNymber}}</li>
              <li>รหัส 08 มี {{sum.type.opad | forMatNymber}}</li>
              <li>รหัส 06 มี {{sum.type.ohox | forMatNymber}}</li>
            </ul>
          </th>
        </slot>
      </tr>
    </thead>
    <thead :class="theadClasses">
    <tr class="recordTab">
      <slot name="columns">
        <th class="text-center" v-for="column in columns" :key="column" v-html="fomatCol(column)"> </th>
      </slot>
    </tr>
    </thead>

    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td class="text-center" v-for="(column, index) in columns" :key="index"   v-if="hasValue(item, column)" v-html="itemValue(item, column)">   </td>
      </slot>
    </tr>
    </tbody>
    <tfoot>
      <tr class="recordTab">
        <slot name="columns">
          <th class="text-center" style="background: #00bcd4 !important;">ทั้งหมด : {{sum.all | forMatNymber}}  </th>
          <th class="text-center" style="background: #f44336 !important;">รอลงทะเบียน : {{sum.nosact | forMatNymber}} </th>
          <th class="text-center" style="background: #9c27b0 !important;">ลงทะเบียนแล้ว : {{sum.act | forMatNymber}} </th>
          <th class="text-center" style="background: #4caf50 !important;">
            <ul class="listCodePhone">
              <li>รหัส 09 มี {{sum.type.okao | forMatNymber}}</li>
              <li>รหัส 08 มี {{sum.type.opad | forMatNymber}}</li>
              <li>รหัส 06 มี {{sum.type.ohox | forMatNymber}}</li>
            </ul>
          </th>
        </slot>
      </tr>
    </tfoot>
  </table>
</template>
<script>
import utils from '@/utils';
  export default {
    name: 'base-table-member-sms',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      sum: {
        type: Object,
        default: '',
        description: " "
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')}` ;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      fomatCol(column){
        let data = {
          "icon":"ไอคอน",
          "number":"เบอร์",
          // "Member":"สมาชิก",
          "active":"<div class='Deposit'>สมาชิก</div>",
          "created_at":"วันที่"
          // "Deposit":"<div class='Deposit'>สมาชิก</div>",
          // "listGetMoney":"เติมมือ",
          // "Withdraw":"<div class='Withdraw'>ถอน</div>",
          // "Memloss":"ผู้แพ้",
          // "Loss":"เครดิตผู้แพ้",
          // "lucre":"<div class='lucre'>กำไร</div>"
        };
        return data[column];

      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        if(column == 'created_at' || column ==  'updated_at'){
          return `<b>${utils.forMatDateTimes(new Date(item[column]))}</b>`;
        }else if(column == 'active'){
          if(item.active == false){
            return `<span style="color: #f44336;"> รอการตอบกลับ  </span>`;
          }else{
            return `<span style="color: #8bc34a;"> ลงทะเบียนแล้ว  </span>`;
          }
        }else if(column == 'number'){
          return item[column].replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3"); //.replace('0','+66');
        }else if(column == 'icon'){
          if(item.active == false){
            return `<i style="font-size: 20px; color: #f44336;" class="tim-icons icon-mobile"></i>`;
          }else{
            return `<i style="font-size: 20px; color: #8bc34a;" class="tim-icons icon-mobile"></i>`;
          }
        }else{
          return (item[column])?item[column]:'-';
        }
        // if(this.isValidDate(new Date(item[column]))){
        //   return utils.forMatDateTimes(new Date(item[column]));
        // }else{
        //   return item[column];
        // }

      }
    }
  };
</script>
<style>
  .Deposit{
    padding: 10px;
    background: #4caf50;
    border-radius: 21px;
  }
  .lucre{
    padding: 10px;
    background: #2196f3;
    border-radius: 21px;
  }
  .Withdraw{
    padding: 10px;
    background: #f44336;
    border-radius: 21px;
  }
  .recordTab > th{
    background: #1e1e2e;
  }
  .listCodePhone{
    margin: 0px;
    padding: 0px;
  }
  .listCodePhone > li{
    display: block;
    color: rgba(255, 255, 255, 0.7);
  }
</style>
