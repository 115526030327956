<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="(column,index) in columns" :key="column">{{columnsTh[index]}}</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td v-for="(column, index) in columns" :key="index"   v-if="hasValue(item, column)">   {{ itemValue(item, column) || 0}} </td>
      </slot>
    </tr>
    <tr>
      <td>ยอดรวมทั้งหมด</td>
      <td v-for="(column, index) in columns.slice(1)" :key="index">
        {{ (total[column] || 0).toLocaleString() }}
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>
import utils from '@/utils';
//const total = {member:0,ftd:0,otherdepo:0,sumdepo:0,sumwith:0};
  export default {
    name: 'base-table-user',
    // data(){
    //   return{
    //     total: {
    //       member:0,
    //       ftd:0,
    //       otherdepo:0,
    //       sumdepo:0,
    //       sumwith:0 
    //     }
    //   }
    // },
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      columnsTh: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      },
      total() {
        // สร้างออบเจ็กต์ที่มีผลรวมของแต่ละคอลัมน์
        return this.data.reduce((acc, item) => {
          this.columns.forEach(column => {
            // ตรวจสอบและแปลงค่าเป็นตัวเลขเพื่อป้องกันการคำนวณที่ผิดพลาด
            acc[column] = (acc[column] || 0) + (Number(item[column]) || 0);
          });
          return acc;
        }, {});
      }
    },
    methods: {
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(), 
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        
        if(column == 'created_at' || column ==  'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else{
          if(column == 'memberCount'){
            //this.total.member += item[column];
          }
          return item[column];
        }

        
        // if(this.isValidDate(new Date(item[column]))){
        //   return utils.forMatDateTimes(new Date(item[column]));
        // }else{
        //   return item[column];
        // }

      }
    }
  };
</script>
<style>
</style>
