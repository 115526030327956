<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="column in columns" :key="column">{{formatColumn(column)}}</th>
        <th v-if="permission('MASTER')">เปิด / ปิด</th>
        <th v-if="permission('GOD')">อัพรูป</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, indexD) in data" :key="indexD" :class="{'tr-listgame active': item['enable'] != true}">
      <slot :row="item">
        <td v-for="(column, index) in columns" :key="index"   v-if="column == 'No'">
          <base-namber style="width: 135px;" addonRightIcon="tim-icons icon-double-right" addonLeftIcon="tim-icons icon-double-left" @change="ChangeStats(index,item)" v-model="item[column]" @input="eventUpdate(index,item)" />
        </td>
        <td v-for="(column, index) in columns" :key="index"   v-if="hasValue(item, column) && column != 'type' && column != 'No'" v-html="itemValue(item, column)"></td>
        <td v-for="(column, index) in columns" :key="index"   v-if="column == 'type'">
          <ul class="tabType">
            <li v-for="(itemType,indexX) in tagType" :key="indexX" :class="{ active: isActive(itemType.tag,item.type) }" v-if="permission('GOD')" @click="selectTags(itemType.tag,indexD)">
              {{itemType.name}}
            </li>
            <li v-for="(itemType,indexX) in tagType" :key="indexX" :class="{ active: isActive(itemType.tag,item.type) }" v-if="!permission('GOD')">
              {{itemType.name}}
            </li>
          </ul>
        </td>
        <td>
          <label class="switch" v-if="permission('MASTER')">
            <input v-model="item.enable" @change="ChangeStats(indexD,item)" type="checkbox" checked>
            <span class="slider round"></span>
          </label>
        </td>
        <td>
          <!-- <base-button type="primary" title=" settings" style="margin-right: 5px;" icon size="sm" v-on:change="onFileChange" @click="imagefile=">

          </base-button> -->
          <div class="row">
            <div class="col-2">
              <base-file type="primary" v-if="permission('GOD')" style="width: 80%;" :icon="true" value="tim-icons icon-cloud-upload-94" @filedata="onFileChange" :block="true" :param="{'path':`/iconsGame/${platform}`,'name':Pahct(item.Img)}" />
            </div>
            <!-- <div class="col-2">
              <base-button v-if="checkUpdata(item.Code)" class="ml-2" type="success" title=" settings" style="margin-right: 5px; padding: 19px;" icon size="sm" @click="ChangeStats(indexD,item)">
                <i class="tim-icons icon-check-2"></i>
              </base-button>
            </div> -->
          </div>

        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
  import utils from '@/utils';
  import axios from 'axios';
  import config from '@/config.js';
  import {BaseFile ,BaseNamber} from "@/components";
  export default {
    components: {
      BaseFile,
      BaseNamber
    },
    name: 'base-table-plat-game',
    data() {
      return {
        imagefile: '',
        uppdata: [],
        tagType:[
          {name:'สล็อต',tag:'slot'},
          {name:'ยิงปลา',tag:'fish'},
          {name:'บิงโก',tag:'bingo'},
          {name:'อาร์เคด',tag:'arcade'}
        ]
      }
    },
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      platform: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      cache:{
        type: Number,
        default: "",
        description: ""
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass()  {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      Pahct(evt){
        try {
          return evt.replace(/\\/g,'/');
        } catch (e) {
          return evt;
        } 
      },
      permission(perm){
        return utils.permission(perm);
      },

      editData(items){
        this.$emit("eventEdit",{items:items})
      },

      ChangeStats(index,item){
        console.log('ChangeStats',item);
        let ukey = this.uppdata.indexOf(item.Code);
        if(ukey > -1){
          this.uppdata.splice(ukey, 1);
        }
       this.$emit("upstatus",{index:index,enable:item.enable,No:item.No})
      },

      isActive(name,type){
        // return (this.data.type.indexOf(name) > -1)?true:false
        if(type === undefined){
          return false
        }else{
          return (name == type)
        }
      },
      formatColumn(col){
        let DataObj = {
          "No":"ลำดับ",
          "Img":"รูป", 
          "Name":"ชื่อ",
          "type":"หมวด"
        }
        return DataObj[col]
      },

      selectTags(select,index){
        this.data[index].type = select;
        this.$emit("selectTagsGame",{index:index,select:select});
      },

      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = (d.getHours().length <= 1)?`0${d.getHours()}`:d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        if(column == 'created_at' || column ==  'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));

        }else if(column == 'Img'){
          return `<div><img src="https://images.sharkbet777.com/iconsGame/${this.platform}/${this.Pahct(item['Img'])}.png?s=80&cache=${this.cache}" style="width: 80px;"/><div/>`
        }else if(column == 'enable'){
          if(item[column]){
            return '<span style="width: 50%;" class="tale-status-active"> เปิดการใช้งาน </span>';
          }else{
            return '<span style="width: 50%;" class="tale-status-reject"> ปิดการใช้งาน </span>';
          }
        }else{
          return item[column] || '-';
        }
        // if(this.isValidDate(new Date(item[column]))){
        //   return utils.forMatDateTimes(new Date(item[column]));
        // }else{
        //   return item[column];
        // }

      },
      onFileChange(e) {
        if(utils.permission('GOD')){
          this.isLoading = true;
           e.param.image = e.Base64.replace('image/jpeg','image/png');// evt.replace(/\\/g,'/');
          axios(config.image(e.param)).then((response)=>{
            this.isLoading = false;
            this.$emit("updateCeahs",Math.floor(Date.now() / 1000))
            this.$alert(response.data.Message,'Success','success');
          },(error)=>{
            this.isLoading = false;
            this.$alert(error.response.data.Message,'Error','error');
          });
        }

      },
      checkUpdata(e){
        if(this.uppdata.indexOf(e) > -1 ) return true;
        return false
      },
      eventUpdate(index,item){ 
        this.ChangeStats(index,item); 
        if(this.uppdata.indexOf(item) < 0){
          this.uppdata.push(item)
        }
      }

    }
  };
</script>
<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.tr-listgame.active {
  background: #1b1c2b;
}
.slider.round:before {
  border-radius: 50%;
}
.tabType{
  padding: 0px;
  margin: 0px;
}
.tabType li{
  display: inline-block;
  background: #383a4a;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.tabType li.active{
  background: #e14eca;
  color: #FFF;
}

</style>
