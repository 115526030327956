<template>
  <div>
    <notifications></notifications>
    <router-view :key="$route.fullPath" style="background-color: transparent;"></router-view>
  </div>
</template>

<script>
  export default {
    methods: {
      // disableRTL() {
      //   if (!this.$rtl.isRTL) {
      //     this.$rtl.disableRTL();
      //   }
      // },
      // toggleNavOpen() {
      //   let root = document.getElementsByTagName('html')[0];
      //   root.classList.toggle('nav-open');
      // }
    },
    mounted() {
      // this.$watch('$route', this.disableRTL, { immediate: true });
      // this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
    }
  };
</script>

<style lang="scss"></style>
