var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{},[_c('button',{staticClass:"btn",class:[
       {'btn-round': _vm.round},
       {'btn-block': _vm.block},
       {'btn-icon btn-fab': _vm.icon},
       ( _obj = {}, _obj[("btn-" + _vm.type)] = _vm.type, _obj ),
       ( _obj$1 = {}, _obj$1[("btn-" + _vm.size)] = _vm.size, _obj$1 ),
       {'btn-simple': _vm.simple},
       {'btn-link': _vm.link},
       {'disabled': _vm.disabled}
     ],attrs:{"type":"button","name":"button"},on:{"click":function($event){return _vm.$refs.file.click()}}},[(_vm.icon)?_c('i',{staticClass:"tim-icons",class:_vm.value}):_vm._e(),(!_vm.icon)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.value)}}):_vm._e()])]),_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"id":"fileS","type":"file","accept":"image/*","value":""},on:{"change":_vm.onFileChange}})])}
var staticRenderFns = []

export { render, staticRenderFns }