<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr class="recordTab">
      <slot name="columns">
        <th class="text-center" v-for="column in columns" :key="column" v-html="fomatCol(column)"> </th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td class="text-center" v-for="(column, index) in columns" :key="index"   v-if="hasValue(item, column)" v-html="itemValue(item, column)">   </td>
      </slot>
    </tr>
    </tbody>
    <tfoot>
    <tr class="recordTab">
      <slot name="columns">
        <th class="text-center">สมัคร : {{sum.members | forMatNymber}} ไอดี </th>
        <th class="text-center">เติมเปิด : {{sum.membersTopup | forMatNymber}} ไอดี </th>
        <th class="text-center">เครดิตฟรี : {{sum.creditfree | forMatNymber}} เครดิต </th>
        <th class="text-center" colspan="2" style="background: #4caf50 !important;">ฝาก : {{sum.Deposit | forMatNymber}} บาท </th>
        <th class="text-center" style="background: #00bcd4 !important;">เติมมือ : {{sum.listGetMoney | forMatNymber}} บาท </th>
        <th class="text-center" colspan="2" style="background: #f44336 !important;">ถอน : {{sum.Withdraw | forMatNymber}} บาท</th>
        <th class="text-center" colspan="2" style="background: #9c27b0 !important;">กำไร : {{sum.lucre | forMatNymber}} บาท</th>
      </slot>
    </tr>
    </tfoot>
  </table>
</template>
<script>
  export default {
    name: 'record-table',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      sum: {
        type: Object,
        default: '',
        description: " "
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')}` ;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      fomatCol(column){
        let data = {
          "dates":"วันที่สรุป",
          "members":"สมัคร",
          "membersTopup":"เติมเปิด",
          "creditfree":"เครดิตฟรี",
          "Deposit":"<div class='Deposit'>ฝาก</div>",
          "listGetMoney":"เติมมือ",
          "Withdraw":"<div class='Withdraw'>ถอน</div>",
          "Memloss":"ผู้แพ้",
          "Loss":"เครดิตผู้แพ้",
          "lucre":"<div class='lucre'>กำไร</div>"
        };
        return data[column];

      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        if(column == 'dates'){
          return `<b>${utils.forMatDateTimes(new Date(item[column]))}</b>`;
        }else if(column == 'members' || column == 'membersTopup' || column == 'creditfree'){
          return `<b>${item[column]}</b>`;
        }else{
          return item[column];
        }
        // if(this.isValidDate(new Date(item[column]))){
        //   return utils.forMatDateTimes(new Date(item[column]));
        // }else{
        //   return item[column];
        // }

      }
    }
  };
</script>
<style>
  .Deposit{
    padding: 10px;
    background: #4caf50;
    border-radius: 21px;
  }
  .lucre{
    padding: 10px;
    background: #2196f3;
    border-radius: 21px;
  }
  .Withdraw{
    padding: 10px;
    background: #f44336;
    border-radius: 21px;
  }
  .recordTab > th{
    background: #1e1e2e;
  }
</style>
