<template>
  <div class="">
    <table class="table tablesorter mb-5" style="border-bottom: solid 3px #3ca372;" :class="tableClass" v-for="(item, index) in data" :key="index" >
      <thead :class="theadClasses">
      <tr class="recordTab" style="color: #FFF;">
        <slot name="columns">
          <th class="text-center" style="color: #FFF;" v-for="column in columns" :key="column" v-html="fomatCol(column)"> </th>
          <th class="text-center" style="color: #FFF;"> VIEW </th>
        </slot>
      </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr>
          <slot :row="item">
            <td class="text-center" v-for="(column, index) in columns" :key="index"  v-if="hasValue(item, column)"  v-html="itemValue(item, column)"> </td>
            <td class="text-center" >
              <a v-if="ShowTab == item.number" @click="ShowTab = ''" class="btn btn-warning btn-icon btn-fab"><i class="tim-icons icon-zoom-split"></i></a>
              <a v-if="ShowTab != item.number" @click="ShowTab = item.number" class="btn btn-info btn-icon btn-fab"><i class="tim-icons icon-zoom-split"></i></a>
            </td>
          </slot>
        </tr>
        <tr v-if="ShowTab == item.number">
          <td class="text-center" colspan="5">
            <table class=" table-striped" style="width: 100%;">
              <thead>
                <tr class="recordTab" style=" color: #FFF;">
                  <th class="text-center"> {{fomatCol('datesAt')}} </th>
                  <th class="text-center"> {{fomatCol('auto')}} </th>
                  <th class="text-center"> {{fomatCol('manual')}} </th>
                  <th class="text-center"> {{fomatCol('deposit')}} </th>
                </tr>
              </thead>
              <tbody :class="tbodyClasses">
                <tr  v-for="(listD, indexD) in item.listD" :key="indexD">
                  <slot :row="item">
                    <td class="text-center"> {{ formatDate(listD.datesAt) }} </td>
                    <td class="text-center">
                        <table style=" width: 80%; margin: auto;">
                        <tbody>
                          <tr>
                            <td>รายการ</td>
                            <td>{{ listD.auto.cou }}</td>
                          </tr>
                          <tr>
                            <td>เงิน</td>
                            <td>{{ listD.auto.amo }}</td>
                          </tr>
                          <tr>
                            <td>ดูข้อมูล</td>
                            <td>
                              <a v-if="listD.auto.cou > 0" @click="InfoLianEve({title:'รายการฝากอัตโนมัติ',list:listD.auto.lis})" style="color: #ff5722;" class="btn btn-link btn-icon btn-fab"><i class="tim-icons icon-bullet-list-67"></i></a>
                              <a v-if="listD.auto.cou == 0"  class="btn btn-link btn-icon btn-fab"><i class="tim-icons icon-alert-circle-exc"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    <td class="text-center">
                      <table style=" width: 80%; margin: auto;">
                        <tbody>
                          <tr>
                            <td>รายการ</td>
                            <td>{{ listD.manual.cou }}</td>
                          </tr>
                          <tr>
                            <td>เงิน</td>
                            <td>{{ listD.manual.amo }}</td>
                          </tr>
                          <tr>
                            <td>ดูข้อมูล</td>
                            <td>
                              <a v-if="listD.manual.cou > 0"  @click="InfoLianEve({title:'รายการฝากด้วยมือ',list:listD.auto.lis})" style="color: #ff5722;" class="btn btn-link btn-icon btn-fab"><i class="tim-icons icon-bullet-list-67"></i></a>
                              <a v-if="listD.manual.cou == 0"  class="btn btn-link btn-icon btn-fab"><i class="tim-icons icon-alert-circle-exc"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td class="text-center">
                        <table style=" width: 80%; margin: auto;">
                        <tbody>
                          <tr>
                            <td>รายการ</td>
                            <td>{{ listD.deposit.cou }}</td>
                          </tr>
                          <tr>
                            <td>เงิน</td>
                            <td>{{ listD.deposit.amo }}</td>
                          </tr>
                          <tr>
                            <td>ดูข้อมูล</td>
                            <td>
                              <a v-if="listD.deposit.cou > 0" @click="InfoLianEve({title:'รายการฝากในบัญชี',list:listD.deposit.lis})" style="color: #ff5722;" class="btn btn-link btn-icon btn-fab"><i class="tim-icons icon-bullet-list-67"></i></a>
                              <a v-if="listD.deposit.cou == 0"  class="btn btn-link btn-icon btn-fab"><i class="tim-icons icon-alert-circle-exc"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </slot>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>

    </table>
  </div>
</template>
<script>
  export default {
    name: 'record-table',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    data() {
      return {
        ShowTab:''
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      InfoLianEve(evt){
        this.$emit("InfoLisEve",evt);
      },
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')}` ;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      fomatCol(column){
        let data = {
          "dates":"วันที่สรุป",
          "type":"ไอคอน",
          "number":"เลขบัญชี",
          "nameBank":"ชื่อธนคาร",
          "name":"ชื่อบัญชี",
          "datesAt":"วันที่",
          "deposit":"ฝาก",
          "auto":"ฝากอัตโนมัติ",
          "manual":"ฝากมือ",
          "cou":"จำนวนครั้ง",
          "amo":"จำนวนเงิน",
          "lis":"รายการ"
        };
        return (data[column] === undefined)?column:data[column];

      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        if(column == 'datesAt'){
          return `<b>${utils.forMatDateTimes(new Date(item[column]))}</b>`;
        }else if(column == 'type'){
          return `<img class="icons-sys" src="/img/icons_sys/${item[column]}.jpg" />`;
        }else{
          return item[column];
        }
        // if(this.isValidDate(new Date(item[column]))){
        //   return utils.forMatDateTimes(new Date(item[column]));
        // }else{
        //   return item[column];
        // }

      }
    }
  };
</script>
<style>
  .Deposit{
    padding: 10px;
    background: #4caf50;
    border-radius: 21px;
  }
  .lucre{
    padding: 10px;
    background: #2196f3;
    border-radius: 21px;
  }
  .Withdraw{
    padding: 10px;
    background: #f44336;
    border-radius: 21px;
  }  
</style>
