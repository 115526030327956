<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th>
          IMAGE
        </th>
        <th>ACTION</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td>
          <img :src="item" style="width: 100px;" />
        </td>
        <td class="td-actions">
          <base-button type="primary" title="Delete" style="margin-right: 5px;" icon size="sm" @click="DeleteImage(data,index)">
            <i class="tim-icons icon-trash-simple"></i>
          </base-button>
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
  /////
  import utils from '@/utils';
  export default {
    name: 'base-table',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      ////// Event
      DeleteImage(items,index){
        items.splice(index, 1); 
        this.$emit("event",items);
      },
      //////
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(), 
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        
        if(column == 'image'){
          return `<img src="${item[column]}" style="width: 100px;" />`;
        }else{
          return item[column];
        }
        // if(this.isValidDate(new Date(item[column]))){
        //   return utils.forMatDateTimes(new Date(item[column]));
        // }else{
        //   return item[column];
        // }

      }
    }
  };
</script>
<style>
</style>
