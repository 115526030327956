import VueRouter from "vue-router";
import routes from "./routes";


// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => { 
  document.title = to.name;
  next();
});

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
//   else next()
// })
// // router.beforeEach((to, from, next) => {
// //   if(localStorage.getItem('token') != null){
// //     next();
// //   }else{
// //     next({
// //         path: '/login',
// //         name: 'Login',
// //         component: Login
// //       });
// //   }
// // })

export default router;
