<template>
  <div class="col-12">
     <div class="bodyLobby" :style="[{'color':data.text}]">
       <div class="bodys" :style="[{'left':slideStyle}]">
         <div class="navi">
           <button type="button" name="button" class="buttonnavi" @click="switchSlide()">
             <i class="tim-icons icon-simple-delete"></i>
             <i class="tim-icons icon-simple-delete"></i>
             <i class="tim-icons icon-simple-delete"></i>
           </button>
         </div>

         <div class="boder">
           <div class="cash">
             <div class="box" :style="[{'background':`linear-gradient(0deg, ${data.submainColor} 0%, ${data.mainColor} 35%)`}]">
               <div class="userCash">
                 <label :style="[{'color':data.text}]">ยอดเงินในเกม</label>
                 <h1 :style="[{'color':data.text}]">$xxx</h1>
               </div>
             </div>
             <div class="balan" :style="[{'background':`linear-gradient(0deg, ${data.submainColor} 0%, ${data.mainColor} 35%)`}]">
               <div class="row">
                 <div class="col-8">
                   <label :style="[{'color':data.text}]">ยอดเงินในเกม</label>
                   <h2 :style="[{'color':data.text}]">$xxx</h2>
                 </div>
                 <div class="col-4">
                   <button type="button" name="button" class="btnCardit"><i class="tim-icons icon-money-coins" style="font-size: 21px;"></i><span> ย้ายเครดิต</span></button>
                 </div>
               </div>
             </div>
           </div>
           <div class="iconGames">
             <ul>
               <li v-for="(items,index) in dataForm.lobbyConfig.menus">
                 <div>
                   <p v-if="!items.enable"> <img :src="`${items.img}?s=120&c=${cache}`" alt=""> </p>
                   <p v-if="items.enable">
                     <base-icon :config="{ icon: data.mainColor,  text :'#FFFFFF',  bg:'#303030' }" :type="items.icons" />
                   </p>
                 </div>
               </li>
             </ul>
           </div>
         </div>
         <div class="footer">
           <ul>
             <li>
               <p :style="[{'color':data.mainColor}]"><i class="tim-icons icon-mobile"></i></p>
               <p :style="[{'color':data.mainColor}]">หน้าแรก</p>
             </li>
             <li>
               <p><i class="tim-icons icon-wallet-43"></i></p>
               <p>ฝาก</p>
             </li>
             <li>
               <p><i class="tim-icons icon-controller"></i></p>
               <p>เล่นเกม</p>
             </li>
             <li>
               <p><i class="tim-icons icon-money-coins"></i></p>
               <p>ถอน</p>
             </li>
             <li>
               <p><i class="tim-icons icon-single-02" style="font-size: 27px;"></i></p>
               <p>โปรไฟล์</p>
             </li>
           </ul>
         </div>
       </div>
       <div class="slide" :style="[{'background':data.mainColor}]">
         <ul>
           <li> <i class="tim-icons icon-mobile"></i>  หน้าแรก</li>
           <li> <i class="tim-icons icon-wallet-43"></i>  ฝาก</li>
           <li> <i class="tim-icons icon-controller"></i>  เล่นเกม</li>
           <li> <i class="tim-icons icon-money-coins"></i>  ถอน</li>
           <li> <i class="tim-icons icon-single-02"></i>  โปรไฟล์</li>
         </ul>
       </div>
     </div>
  </div>
</template>
<script>

  export default {
    components: {  },
    name: "view-lobby",
    props: {
      data: {
        type: Object,
        description: "[]"
      },
      dataForm: {
        type: Object,
        description: "[]"
      },
    },
    data() {
      return { slideStyle : '0px', }
    },
    computed: {
    },
    methods: {
      switchSlide(){
        if(this.slideStyle == '0px'){
          this.slideStyle = '200px';
        }else{
          this.slideStyle = '0px';
        }
      },
    },
    mounted() {

    },
  }
</script>
<style>
  .flickity-page-dots {
    bottom: 15px !important;
  }
  .flickity-page-dots .dot {
    background: #fff  !important;
  }
  .textarea{
    max-height: 2000px !important;
  }
</style>
