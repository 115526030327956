<template>
  <div class="">
    <ul class="logBoxGameWinLose">
      <li id="win" :style="`width: ${prosessBar('Credit',{Credit:data.Credit,Debit:data.Debit})}%;`">
        <h2>{{data.Credit}}</h2>
        <p>รับ</p>
      </li>
      <li id="lose" :style="`width: ${prosessBar('Debit',{Credit:data.Credit,Debit:data.Debit})}%;`">
        <h2>{{data.Debit}}</h2>
        <p>แทง</p>
      </li>
      <li style="width: 33.33%;">
        <h2>{{withdraw.t}}</h2>
        <p>ทุน</p>
      </li>
      <li style="width: 33.33%;">
        <h2>{{(data.Credit-data.Debit)}}</h2>
        <p>{{(data.Credit-data.Debit > 0)?'กำไร':'ขาดทุน'}}</p>
      </li>
      <li style="width: 33.33%;">
        <h2>{{withdraw.w}}</h2>
        <p>ถอน</p>
      </li>
    </ul>
    <div class="mb-5 mt-5 logBoxGame" v-for="items in data.GameLog">
      <div class="GameN row">
        <div class="col-lg-2 text-center">
          <img :src="`${domain}/typegame/${items.game}.png?s=180`" :alt="items.game" style="height: 100px;">
        </div>
        <div class="col-lg-8" style="position: relative;">

        </div>
        <div class="col-lg-1">
          <div class="text-center" style="padding-top: 15px;">
            <p>รายการ</p>
            <h1>{{items.log.length}}</h1>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="text-center" style="padding-top: 27px;">
             <base-button v-if="isShowLog == items.game" type="danger" :icon="true" @click="ShowLog(items.game)"> <i class="tim-icons icon-simple-remove"></i> </base-button>
             <base-button v-if="isShowLog != items.game" type="warning" :icon="true" @click="ShowLog(items.game)"> <i class="tim-icons icon-zoom-split"></i> </base-button>
          </div>
        </div>
        <hr>
      </div>
      <div class="LogRo " v-for="logs in items.log" v-if="isShowLog == items.game" >
        <table class="table">
          <thead>
            <tr class="roundId">
              <th> <strong>รอบ</strong> </th>
              <th colspan="5"> {{logs.roundId}}  </th>
            </tr>
            <tr >
              <th class="text-center"> เกม </th>
              <th class="text-center"> กลุ่ม  </th>
              <th class="text-center"> แทง-ผลลัพท์ </th>
              <th class="text-center"> ยอดก่อนหน้า </th>
              <th class="text-center"> ยอดล่าสุด	</th>
              <th class="text-center"> เวลาแทง	</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for=" roundLog in logs.round ">
              <td style="width: 185px;" class="text-center">
                <div class="imageRadius" :style="`background-image: url(${domain}/iconsGame/${roundLog.platformCode}/${roundLog.gameImg}.png?s=120);`">
                </div>
                <p>{{roundLog.gameName}}</p>
              </td>
              <td class="text-center"> {{roundLog.type}} </td>
              <td class="text-center">
                <span style="color: rgb(255, 0, 0);" v-if="roundLog.method == 'debit' && roundLog.amount > 0">-{{roundLog.amount | forMatMony}}</span>
                <span style="color: rgb(56, 255, 0);" v-if="roundLog.method != 'debit' && roundLog.amount > 0">+{{roundLog.amount | forMatMony}}</span>
                <span style="color: rgb(255, 255, 0);" v-if="roundLog.method != 'debit' && roundLog.amount == 0">-{{roundLog.amount | forMatMony}}</span>
                <span style="color: rgb(0, 231, 255);" v-if="roundLog.method == 'debit' && roundLog.amount == 0">+{{roundLog.amount | forMatMony}}</span>
              </td>
              <td class="text-center"><span style="color: rgb(255, 74, 233);">{{roundLog.before | forMatMony}}</span></td>
              <td class="text-center"><span style="color: rgb(90, 110, 237);">{{roundLog.after | forMatMony}}</span></td>
              <td class="text-center">{{roundLog.created_at | forMatDateTimes}} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import utils from '@/utils';
  export default {
    name: 'base-table-log-game',
    props: {
      data: {
        type: Object,
        default: () => []
      },
      domain: {
        type: String,
        default: () => []
      },
      withdraw:{
        type: Object,
        default: () => []
      }
    },
    data() {
      return {
        isShowLog: '',
      }
    },
    computed: {

    },
    methods: {
      ShowLog(name){
        this.isShowLog = (this.isShowLog == name)?'':name;
      },
      prosessBar(met,data){
        if(met == 'Credit'){
          let sum = data.Credit+data.Debit;
          let persen = data.Credit/sum*100;
          return (persen == 100)?90:(persen == 0)?10:persen;
        }else{
          let sum = data.Credit+data.Debit;
          let persen = data.Debit/sum*100;
          return (persen == 100)?90:(persen == 0)?10:persen;
        }
      }
    }
  };
</script>
<style>
.imageRadius{
  margin: auto;
  margin-bottom: 10px;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  /* background-size:100% 100%; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.logBoxGame{
  border-bottom: 3px solid rgb(29, 29, 39);
  padding: 10px;
  background: #1f2133;
  border-radius: 10px;
}
.LogRo{
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  background: #27293d;
  border-radius: 10px;
}
.roundId{
  background: #1d1d27;
  border-radius: 17px;
}
.logBoxGameWinLose{
  padding: 0px;
  margin: 0px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background: #1d1d27;
}
.logBoxGameWinLose li{
   display: inline-block;
   text-align: center;
   width: 50%;
   padding: 10px;
}
.logBoxGameWinLose li > p,.logBoxGameWinLose li > h2 {
  padding: 0px;
  margin: 0px;
}
.logBoxGameWinLose li#win{
  background: #8bc34a;
}
.logBoxGameWinLose li#lose{
  background: #e91e63;
}
.ListSumLog{
  position: absolute;
  top: 15px;
  right: 125px;
}
.butBerge{
  padding: 0px;
  margin: 10px 0px;
}
.butBerge > li{
  display: block;
  margin: -15px 0px;
}
</style>
