<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="column in columns" :key="column">{{varColumn(column)}}</th>
        <th v-if="permission('MASTER')">{{varColumn('event')}}</th>
        <th v-if="permission('MASTER')">{{varColumn('view')}}</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index" :class="{'tr-listgame active': item['enable'] != true}">
      <slot :row="item">
        <td v-for="(column, index) in columns" :key="index" v-if="column == 'no'" style="width: 15%;">
          <base-namber style="width: 135px;" addonRightIcon="tim-icons icon-double-right" addonLeftIcon="tim-icons icon-double-left" @change="ChangeStats(index,item)" v-model="item[column]" @input="eventUpdate(index,item)" />
        </td>
        <td v-for="(column, index) in columns" :key="index" v-if="column != 'no'" v-html="itemValue(item, column)"></td>
        <td>
          <label class="switch" v-if="permission('MASTER')">
            <input v-model="item.enable" @change="ChangeStats(index,item)" type="checkbox" checked>
            <span class="slider round"></span>
          </label>
        </td>
        <td>
          <base-button type="primary" title=" settings" style="margin-right: 5px;" icon size="sm" @click="editData(item)">
            <i class="tim-icons icon-settings"></i>
          </base-button>
          <!-- <base-button v-if="checkUpdata(item.name)" class="ml-2" type="success" title=" settings" style="margin-right: 5px;" icon size="sm" @click="ChangeStats(index,item)">
            <i class="tim-icons icon-check-2"></i>
          </base-button> -->
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
  import utils from '@/utils';
  import { BaseNamber } from "@/components"
  export default {
    components: {
      BaseNamber
    },
    data() {
      return {
        uppdata: [],
      }
    },
    name: 'base-table-game',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      cache:{
        type: Number,
        default: "",
        description: ""
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass()  {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      permission(perm){
        return utils.permission(perm);
      },

      editData(items){
        this.$emit("eventEdit",{items:items})
      },

      ChangeStats(index,item){
        //this.$emit("ChangeStats",{_id:item._id,status:item.status})
        let ukey = this.uppdata.indexOf(item.name);
        if(ukey > -1){
          this.uppdata.splice(ukey, 1);
        }
        this.$emit("ChangeStats",{index:index,item:item})
      },

      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      varColumn(d){
        let textCol = '';
        switch(d) {
          case 'no':
            textCol = 'ลำดับ'
            break;
          case 'img':
            textCol = 'รูป'
            break;
          case 'name':
            textCol = 'ชื่อ'
            break;
          case 'payoff':
            textCol = 'ผลตอบแทน'
            break;
          case 'enable':
            textCol = 'สถานะ'
            break;
          case 'event':
            textCol = 'กระทำ'
            break;
          case 'view':
            textCol = 'ชม'
            break;
          default:
           textCol = d
            // code block
        }
        return textCol;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        if(column == 'created_at' || column ==  'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else if(column == 'img'){
          return `<div><img src="https://images.csharkbet777.com/typegame/${item['name']}.png?s=80&cache=${this.cache}" style="width: 80px;"/><div/>`
        }else if(column == 'enable'){
          if(item[column]){
            return '<span style="width: 50%;" class="tale-status-active"> เปิดการใช้งาน </span>';
          }else{
            return '<span style="width: 50%;" class="tale-status-reject"> ปิดการใช้งาน </span>';
          }
        }else{
          return item[column];
        }
        // if(this.isValidDate(new Date(item[column]))){
        //   return utils.forMatDateTimes(new Date(item[column]));
        // }else{
        //   return item[column];
        // }

      },
      checkUpdata(e){
        if(this.uppdata.indexOf(e) > -1 ) return true;
        return false
      },
      eventUpdate(index,item){
        this.ChangeStats(index,item); 
        if(this.uppdata.indexOf(item) < 0){
          this.uppdata.push(item)
        }
      }

    }
  };
</script>
<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.tr-listgame.active {
  background: #1b1c2b;
}
.slider.round:before {
  border-radius: 50%;
}
</style>
