<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="column in columns" :key="column">{{column}}</th>
        <th>Event</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index" v-bind:class="{ blockUser: (item.status != true) }">
      <slot :row="item">
        <td v-for="(column, index) in columns"
            :key="index"
            v-if="hasValue(item, column)" v-html="itemValue(item, column)">
        </td>
        <td class="td-actions">
          <base-button type="info" title="ข้อมูลสมาชิก" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'info','item':item})">
            <i class="tim-icons icon-badge"></i>
          </base-button>
          <base-button type="warning" title="บ๊อค" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'block','item':item})" v-if="item.status == true">
            <i class="tim-icons icon-lock-circle"></i>
          </base-button>
          <base-button type="primary" title="ปลดบ๊อค" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'block','item':item})" v-if="item.status != true">
            <i class="tim-icons icon-lock-circle"></i>
          </base-button>
          <base-button type="danger" title="ลบ" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'del','item':item})">
            <i class="tim-icons icon-trash-simple"></i>
          </base-button>
          
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
  import config from '../config.js';
  import utils from '@/utils';
  export default {
    name: 'base-table-mods',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        //return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
        return `${[year, month, day].join('-')}` ;
      },
      hasValue(item, column) {
        return typeof item[column.toLowerCase()];
        //return item[column.toLowerCase()] !== "undefined";
      },
      modalsUser(data){
        if(data.eve == "block"){
          if(data.item.status){
            data.before = data.item.status;
            data.item.status = false;
            this.$emit("user-modals",data);
          }else{
            data.before = data.item.status;
            data.item.status = true
            this.$emit("user-modals",data);
          }
        }else if(data.eve == "del"){
          data.before = data.item.status;
          data.item.status = null;
          this.$emit("user-modals",data);
        }else{
          this.$emit("user-modals",data);
        }

      },
      itemValue(item, column) {
        if(column == 'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else if(column == 'status'){
          if(item[column]){
            return '<span style="width: 50%;" class="tale-status-active"> ใช้งาน </span>';
          }else{
            return '<span style="width: 50%;" class="tale-status-reject"> ระงับ </span>';
          }
        }else{
          return item[column] || '-'
        }

      }
    }
  };
</script>
<style>
  .status-icon{
    width: 20px !important;
    height: 20px !important;
    font-size: 7px !important;
    border-radius: 50% !important;
    display: inline-block !important;
  }
  .blockUser{
    background: rgba(82,95,127,0.2);
  }
</style>
