<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="column in columns" :key="column">{{columnValue(column)}}</th>
        <th> Event </th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td v-for="(column, index) in columns" :key="index"   v-if="hasValue(item, column)" v-html="itemValue(item, column) || '-'" >   </td>
        <td class="td-actions">
          <base-button type="info" title="ข้อมูล" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'info','index':index,'item':item})">
            <i class="tim-icons icon-pencil"></i>
          </base-button>
          <base-button type="danger" title="บ๊อค" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'block','index':index,'item':item})" v-if="item.status == true">
            <i class="tim-icons icon-lock-circle"></i>
          </base-button>
          <base-button type="primary" title="ปลดบ๊อค" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'block','index':index,'item':item})" v-if="item.status != true">
            <i class="tim-icons icon-lock-circle"></i>
          </base-button>
          <base-button type="danger" title="ลบ" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'del','index':index,'item':item})">
            <i class="tim-icons icon-trash-simple"></i>
          </base-button>
          <base-button type="danger" title="ปักหมุด" style="margin-right: 5px;" icon size="sm" @click="modalsPin({'eve':'none','index':index,'item':item})" v-if="item.pin > 0">
            <i class="tim-icons icon-pin"></i>
          </base-button>
          <base-button type="success" title="ปักหมุด" style="margin-right: 5px;" icon size="sm" @click="modalsPin({'eve':'block','index':index,'item':item})" v-if="item.pin < 1">
            <i class="tim-icons icon-pin"></i>
          </base-button>
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
import utils from '@/utils';
  export default {
    name: 'base-table',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      columnValue(column){
        let ColData ={
          "title":"หัวข้อ",
          "agent":"ใช้ได้เฉพาะ",
          "bonut":"โบนัส",
          "getbonutLinit":"รับสูงสุด",
          "withdrawLimit":"ถอนได้สูงสุด",
          "pin":"ปักหมุด",
          "status":"สถานะ"
        };
        return ColData[column];
      },
      modalsPin(item){ // {'eve':'block','index':index,'item':item}
        this.$emit("pin-modals",item);
      },
      modalsUser(item){
        this.$emit("use-modals",item);
      },
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        if(column == 'created_at' || column ==  'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else if(column == 'status'){
          if(item[column]){
            return '<span style="width: 50%;" class="tale-status-active"> <i class="tim-icons icon-check-2"></i> </span>';
          }else{
            return '<span style="width: 50%;" class="tale-status-reject"> <i class="tim-icons icon-simple-remove"></i> </span>';
          }
        }else if( column == 'agent'){
          return item[column].join(' | ');

        }else if( column == 'pin'){
          if(item[column] > 0){
            return '<span style="width: 50%;" class="tale-status-reject"> <i class="tim-icons icon-pin"></i> </span>';
          }else{
            return item[column];
          }
        }else{
          return item[column];
        }
        // if(this.isValidDate(new Date(item[column]))){
        //   return utils.forMatDateTimes(new Date(item[column]));
        // }else{
        //   return item[column];
        // }

      }
    }
  };
</script>
<style>
</style>
