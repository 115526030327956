<template>
  <div class="bsod container mt-5">
    <h1 class="neg title"><span class="bg">Error - 404</span> <span class="spie-err">|</span> </h1>
    <p>An error has occured, to continue:</p>
    <p>* Return to our homepage.<br />
    * Send us an e-mail about this error and try later.</p>
    <nav class="nav text-center error-nav"> 
      <router-link to="/dashboard">index</router-link>
      <router-link to="/">webmaster</router-link>
    </nav>
  </div>
</template>

<script>
// @import 'https://fonts.googleapis.com/css?family=VT323';
import '@/assets/css/NotFoundPage.css'
export default {
  mounted() {
    document.body.style.background = '#0000ff'
  },
};
</script> 
