import { jwtDecode } from "jwt-decode";
export default {
    localDB:{
        get: (param)=>{ return JSON.parse(localStorage.getItem(param));},
        act: (param)=>{ return localStorage.getItem(param);},
        set: (param,data)=>{ return localStorage.setItem(param,JSON.stringify(data));},
        remove: (param)=>{ return localStorage.removeItem(param);},
        jwt: (param)=>{  return jwtDecode(localStorage.getItem(param));  }
    },
    OPwithdraw:(ParamData)=> {
        let credit = parseInt(ParamData.credit);
        if(ParamData.promotion == '-'){
            return {
                credit: credit,
                condition: 0,
                withMax : 0,
                statust: false
            };
        }else{
            if(ParamData.promotion.typeWithdraw == 'multiply'){
            if( credit >= (ParamData.usePromotion.useProPoint*ParamData.promotion.withdraw) ){
                return {
                    credit: credit,
                    condition: (ParamData.usePromotion.useProPoint*ParamData.promotion.withdraw),
                    withMax : ParamData.promotion.withdrawLimit,
                    statust: true
                };
            }else{
                return {
                    credit: credit,
                    condition: (ParamData.usePromotion.useProPoint*ParamData.promotion.withdraw),
                    withMax : ParamData.promotion.withdrawLimit,
                    statust: false
                };
            }
            /////// positive
            }else if(ParamData.promotion.typeWithdraw == 'positive'){
            if( credit >= (ParamData.usePromotion.useProPoint+ParamData.promotion.withdraw) ){
                return {
                    credit: credit,
                    condition: (ParamData.usePromotion.useProPoint+ParamData.promotion.withdraw),
                    withMax : ParamData.promotion.withdrawLimit,
                    statust: true
                };
            }else{
                return {
                    credit: credit,
                    condition: (ParamData.usePromotion.useProPoint+ParamData.promotion.withdraw),
                    withMax : ParamData.promotion.withdrawLimit,
                    statust: false
                };
            }
            /////// none
            }else{
            if(credit >= ParamData.promotion.withdraw){
                return {
                    credit: credit,
                    condition: ParamData.promotion.withdraw,
                    withMax : ParamData.promotion.withdrawLimit,
                    statust: true
                };
            }else{
                return {
                    credit: credit,
                    condition: ParamData.promotion.withdraw,
                    withMax : ParamData.promotion.withdrawLimit,
                    statust: false
                };
            }
            }
        }


      },
    formatNumBank: (ParamData)=>{
      return ParamData.replace(/(\d{3})(\d{1})(\d{5})(\d{1})/, "$1-$2-$3-$4");
    },
    actKeyDe:(xkey)=>{
     let Textcnc  = xkey.cnc.substring(1, (xkey.key+1)); 
     console.log('v',xkey);
     return  xkey.cnc.split(Textcnc).join('');
    },
    activate:()=>{
      try {
        let adminInfo = jwtDecode(localStorage.getItem('token'));
        let today = Math.floor(Date.now() / 1000);
        if(adminInfo.timeoutExp > today){
          // axios({
          //   method: 'get',
          //   url: 'https://api.ipify.org/?format=json',
          //   headers: { }
          // }).then((responseIP)=>{
            // axios(config.api('post','/activate',{
            //   "_id": adminInfo._id,
            //   "secret": adminInfo.secret
            // },"98.207.254.136")).then((response)=>{
            //   return true;
            // },(error)=>{
            //   localStorage.removeItem('token');
            //   return false;
            // });
          // },(e)=>{
          //   localStorage.removeItem('token');
          //   return false;
          // });
          return true;
        }else{
          localStorage.removeItem('token');
          return false;
        }
      } catch (e) {
        localStorage.removeItem('token');
        return false;
      }
    },
    forMatMony:(input) =>{
       return parseInt(input).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    forMatNymber:(input)=>{
       return parseInt(input).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0];
    },
    forMatDateTimes:(timestamp) =>{
      let timeS = (timestamp)? new Date(timestamp) : new Date();
      let d = timeS,
          hour = (()=>{
            let currentHours = d.getHours();
            return  ("0" + currentHours).slice(-2);
          })(),
          minutes = (()=>{
            let currentHours = d.getMinutes()
            return  ("0" + currentHours).slice(-2);
          })(),
          seconds = (()=>{
            let currentHours = d.getSeconds()
            return  ("0" + currentHours).slice(-2);
          })(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;
      return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
    },
    forMatDate:(timestamp) =>{
      let timeS = (timestamp)? new Date(timestamp) : new Date();
      let d = timeS,
          hour = d.getHours(),
          minutes = d.getMinutes(),
          seconds = d.getSeconds(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;
      return `${[year, month, day].join('-')}` ;
    },
    nameMonth:() =>{
      let d = new Date();
      let monthNamesThai = ["มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤษจิกายน","ธันวาคม"];
      return monthNamesThai[d.getMonth()];
    },
    getDaysInMonth:() =>{
      let d = new Date();
      let month = d.getMonth();
      let year = d.getFullYear();
      let date = new Date(year, month, 1);
      let days = [];
      while (date.getMonth() === month) {
        days.push(`วันที่ ${new Date(date).toISOString().slice(0, 10).split('-')[2]}`);
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    getDaysInMonthNum:() =>{
      let d = new Date();
      let month = d.getMonth();
      let year = d.getFullYear();
      let date = new Date(year, month, 1);
      let days = [];
      while (date.getMonth() === month) {
        days.push(0);
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    permission: (namePermis)=>{
      let adminInfo = jwtDecode(localStorage.getItem('token'));
        if(namePermis == 'GOD' && adminInfo.permission.indexOf('GOD') > -1){
          return true;
        }else if(namePermis != 'GOD' && (adminInfo.permission.indexOf(namePermis) > -1 || adminInfo.permission.indexOf('MASTER') > -1 || adminInfo.permission.indexOf('GOD') > -1)){
          return true;
        }else{
          return false;
        }
    },
    getBase64Img:(file)=>{
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    makeId:(length)=>{
      let result           = '';
      let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    short:(length)=>{
      let result           = '';
      let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      let charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    findIndexByObj:(array,find)=>{
      let objkey = Object.keys(find);
      for (var i = 0; i < array.length; i++) {
        if(array[i][objkey] == find[objkey]) return array[i];
      }
      return false;
    },
    getDomain:()=>{
      let hostName = window.location.hostname;
      let Domain = hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
      if(Domain == 'localhost'){ //// Dev
        return 'slotzeed.com';
      }else{ /// prod
        return Domain;
      }
    },
    actKeyDomain:(()=>{
      let hostName = window.location.hostname;
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1); 
    })(),
    Sort:{
      aec:(dataobj)=>{
        const keys = Object.keys(dataobj);
        if(dataobj[keys].length){
         return dataobj[keys].sort((a, b) => (a[keys] > b[keys] ? -1 : 1));
        }else{
         return [];
        } 
      },
      desc:(dataobj)=>{
        const keys = Object.keys(dataobj);
        if(dataobj[keys].length){
          return dataobj[keys].sort((a, b) => (b[keys] > a[keys] ? -1 : 1));
        }else{
          return [];
        }
      }
    },
    sortA:(dataobj)=>{
       const keys = Object.keys(dataobj);
       if(dataobj[keys].length){
        return dataobj[keys].sort((a, b) => (a[keys] > b[keys] ? -1 : 1));
       }else{
        return [];
       } 
    },
    sortB:(dataobj)=>{
      const keys = Object.keys(dataobj);
      if(dataobj[keys].length){
        return dataobj[keys].sort((a, b) => (b[keys] > a[keys] ? -1 : 1));
      }else{
        return [];
      }
       
    },
}
