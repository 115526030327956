<template>
  <div class="form-group">
    <label v-if="label"  class="control-label">{{label}}</label>
    <hr>
    <textarea v-if="!NewLine && !Hljs" v-on="listeners" ref="textarea"  :disabled="disabled" name="name" class="textarea form-control" :value="value" :class="{'Highlight':highlight}" ></textarea>
    <div v-if="NewLine && newline" v-on="listeners" class="textarea form-control newline" v-html="htmlHighlight(value)" @click="onFocus()"> </div>
    <div v-if="Hljs && highlight" class="textarea form-control Highlight" v-html="CSSHighlight(value)" @click="onFocus()"> </div>
  </div>
</template>
<script>
import hljs from 'highlight.js/lib/core.js';
import '@/plugins/highlight/default.min.css';
 export default {
   name: 'text-area',
   model: {
     prop: 'value',
     event: 'input'
   },
   props: {
     disabled: Boolean,
     newline: Boolean,
     highlight: Boolean,
     value: {
       type: String, // striped | hover
       default: "",
       description: "text"
     },
     style: {
       type: String, // striped | hover
       default: "fsdfsdfsf",
       description: "text"
     },
     label: {
       type: String,
       description: "Input label"
     },
   },
   data() {
     return {
       NewLine: this.newline,
       Hljs : this.highlight
     }
   },
   computed: {
     listeners() {
       return {
         ...this.$listeners,
         input: this.onInput,
         blur: this.onBlur,
         focus: this.onFocus
       }
     }
   },
   methods: {
     htmlHighlight(value){
       var match = /\r|\n/.exec(value);
       let newline = match.input.split("\n");
       let tagLi = '';
       for (var i = 0; i < newline.length; i++) {
        if(newline[i] != ''){
          tagLi +=`<li><i class="tim-icons icon-bell-55"></i> <span>${newline[i]}</span> </li>`
        }
       }
       return `<ul class="newLineUI">${tagLi}</ul>`;
     },
     CSSHighlight(value){
       let r = hljs.highlight(value, {language: 'css'}).value;
       return r.replace(/\r/g,"<br/>").replace(/\n/g,"<br/>")
     },
     onInput(evt) {
       this.$emit('input', evt.target.value)
     },
     onFocus() {
       if(this.newline || this.highlight){
         this.NewLine = false;
         this.Hljs = false
         setTimeout( ()=> {
           this.$refs.textarea.focus();
         }, 100);
       }
       this.focused = true;
     },
     onBlur() {
       if(this.newline || this.highlight){
         this.NewLine = true;
         this.Hljs = true
       }
       this.focused = false;
     },

   }
 }
</script>
<style>
  .newline{
    cursor: text !important;
  }
  .Highlight{
    height: 1200px !important
  }
  .newLineUI{
    padding: 0px;
    margin: -12px;
  }
  .newLineUI > li{
    display: block;
    background: #1e1e2d;
    margin: 8px 0px;
    padding: 10px;
    border-radius: 10px;
  }
  .newLineUI > li > span{
    margin-left: 12px;
  }
</style>
