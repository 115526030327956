<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="column in columns" :key="column"> 
          <button class="sorttable" @click="SortTable(column)"> 
            {{columValue(column)}}
            <i v-show="sort.col != column && (sort.even == 'desc' || sort.even == 'aec')" class="fa-solid fa-sort"></i>
            <i v-show="sort.col == column && sort.even == 'desc'" class="fa-solid fa-sort-up"></i>
            <i v-show="sort.col == column && sort.even == 'aec'" class="fa-solid fa-sort-down"></i>
          </button>
        </th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td v-for="(column, index) in columns" :key="index"   v-if="hasValue(item, column)" v-html="itemValue(item, column)"> </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
import utils from '@/utils';
  export default {
    name: 'base-table-gmonney',
    props: {
      sort:{
        type: Object,
        default: () => {},
      },
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      columValue(column) {
        if(column == 'atdate'){
          return '@DATE';
        }else{
          return column.toUpperCase();
        }
      },
      itemValue(item, column) {

        if(column == 'created_at' || column ==  'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else if(column == 'type'){
          return `<img class="icons-sys" src="/img/icons_sys/${item[column]}.jpg" />`;
        }else{
          return item[column];
        }
      },
      SortTable(column){ 
        this.$emit('SortTable',column);
      }
    }
  };
</script>
<style>
</style>
