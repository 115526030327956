<template>
   <div>
      <div class="">
        <button
        @click="$refs.file.click()"
        class="btn"
        :class="[
          {'btn-round': round},
          {'btn-block': block},
          {'btn-icon btn-fab': icon},
          {[`btn-${type}`]: type},
          {[`btn-${size}`]: size},
          {'btn-simple': simple},
          {'btn-link': link},
          {'disabled': disabled}
        ]"
        type="button" name="button">
        <i class="tim-icons" :class="value" v-if="icon"></i>
        <span v-if="!icon" v-html="value"></span>
      </button>
      </div>
      <input id="fileS" type="file" ref="file" accept="image/*" style="display: none" value="" v-on:change="onFileChange">
   </div>
</template>
<script>
  export default {
    name: 'base-input-file',
    props: {
      icon: Boolean,
      link: Boolean,
      round: Boolean,
      simple: Boolean,
      block: Boolean,
      loading: Boolean,
      disabled: Boolean,
      value: {
        type: String, // striped | hover
        default: "",
        description: "text"
      },
      size: {
        type: String,
        default: "",
        description: "Button size (sm|lg)"
      },
      param:{
        type: Object,
        default: "",
        description: "{'path':`/lobby`,'type':'logo','name':'logo',index:''}"
      },
      type: {
        type: String,
        default: "default",
        description: "Button type (primary|secondary|danger etc)"
      },
    },

    computed: {

    },
    methods: {
      onFileChange(e) {
        let file = e.target.files[0];
        let toBase64  = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
        toBase64.then((resolve)=>{ 
          this.$emit("filedata",{
            Base64: resolve,
            param: this.param
          });
        });
      }
    }
  }
</script>
<style>

</style>
