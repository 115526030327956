<template>
  <div class="form-group"
       v-on="listeners"
       :class="{
          'input-group': hasIcon,
          'input-group-focus': focused
       }">
    <slot name="addonRight">
      <button v-if="value>0" type="button" class="btn-sm btn" name="button" @click="btnPush('minus')">-</button>
      <button v-if="value<=0" disabled type="button" class="btn-sm btn" name="button">-</button>
    </slot>
    <slot>
      <input
        v-if="text ==''"
        :value="value"
        v-bind="$attrs"
        type="text"
        class="form-control"
        aria-describedby="addon-right addon-left">
    </slot>
    <slot name="addonRight">
     <button type="button" style="margin-left: -16px; z-index: 100;" class="btn-sm btn " name="button" @click="btnPush('plus')">+</button>
    </slot>
  </div>
</template>
<script>
  export default {
    inheritAttrs: false,
    name: "base-namber",
    props: {
      label: {
        type: String,
        description: "Input label"
      },
      value: {
        type: [String, Number],
        description: "Input value"
      },
      text: {
        type: [String, Number],
        default:'',
        description: "Input icon on the right"
      },
      addonRightIcon: {
        type: String,
        description: "Input icon on the right"
      },
      addonLeftIcon: {
        type: String,
        description: "Input icon on the left"
      },
      click:{

      }
    },
    model: {
      prop: 'value',
      event: 'input'
    },
    data() {
      return {
        focused: false,
        valueNumber: this.value,
        focus: this.onFocus
      }
    },
    computed: {
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots;
        return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
      },
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
          blur: this.onBlur,
          focus: this.onFocus
        }
      }
    },
    methods: {
      btnPush(eve){
        if(eve == 'plus'){
          this.valueNumber++;
          this.$emit('input', (!isNaN(parseInt(this.valueNumber)))?parseInt(this.valueNumber):0); 
        }else{
          this.valueNumber--;
          this.$emit('input', (!isNaN(parseInt(this.valueNumber)))?parseInt(this.valueNumber):0);
        }
      },
      onInput(evt) {
        this.valueNumber = (!isNaN(parseInt(evt.target.value)))?parseInt(evt.target.value):0;
        this.$emit('input', (!isNaN(parseInt(evt.target.value)))?parseInt(evt.target.value):0)
      },
      onFocus() {
        this.focused = true;
      },
      onBlur() {
        this.focused = false;
      }
    }
  }
</script>
<style>

</style>
