<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="(column,index) in columns" :key="column">{{columnsTh[index]}}</th> 
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td v-for="(column, index) in columns"
            :key="index"
            v-if="hasValue(item, column)" v-html="itemValue(item, column)">
        </td> 
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
  import config from '@/config.js';
  import utils from '@/utils'; 
  export default {
    name: 'base-table-withdraw-logs',
    data() {
      return {
        permission: utils.permission
      }
    }, 
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      columnsTh: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      }, 
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      logGame(item){
        window.open(`/?gid=${item.gameId}#/gamelog`);
      },
      hasValue(item, column) {
        return typeof item[column];
        //return item[column] !== "undefined";
      },
      modalsUser(item){
        this.$emit("withd-modals",item);
      },
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      itemValue(item, column) { 
        if(column == 'created_at' || column ==  'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else if(column == 'status'){
          return (item[column] == 'success')?`<span class="text-status-active">โอนสำเร็จ</span>`:`<span class="text-status-failed">โอนไม่สำเร็จ</span>`
        }else if(column == 'amount'){
          return (isNaN(parseFloat(item[column]).toFixed(2)))?'-':parseFloat(item[column]).toFixed(2);
        }else if(column == 'qrString'){
          if(item['status'] == 'success'){
            return `<img src="https://chart.googleapis.com/chart?cht=qr&chl=${item[column]}&chs=180x180&choe=UTF-8" alt="${item[column]}">`
          }else{
            return item[column];
          }
        }else{ 
          return item[column];
        }

      }
    }
  };
</script>
<style>
 
.text-status-active{
  color: #00bf9a;
} 
.text-status-failed{ 
  color: #ec250d; 
}
</style>
