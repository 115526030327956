/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from 'vue-router-prefetch'
import App from "./App";
import utils from '@/utils';
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";
import VueClipboard from 'vue-clipboard2'
import VTooltip from 'v-tooltip'
import VueSimpleAlert from "vue-simple-alert";
import VueHighlightJS from 'vue-highlightjs'

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n"
import './registerServiceWorker' 

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueClipboard);
Vue.use(VTooltip);
Vue.use(VueSimpleAlert);
Vue.use(VueHighlightJS);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
//// <pre v-highlightjs="sourcecode"><code class="javascript"></code></pre>
//// <pre v-highlightjs><code class="javascript">const s = new Date().toString()</code></pre>

/* eslint-disable no-new */
// const router = new VueRouter({
//   routes
// })

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount("#app");

Vue.filter('json', value => { return JSON.stringify(value, null, 2) } ) ///
Vue.filter('fotmatNumBank', value => { return value.replace(/(\d{3})(\d{1})(\d{5})(\d{1})/, "$1-$2-$3-$4"); } ) ///
Vue.filter('forMatMony', input => { return parseInt(input).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); } ) ///
Vue.filter('forMatNymber', input => { return parseInt(input).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]; } ) ///
Vue.filter('forMatDateTimes', timestamp => { let timeS = (timestamp)? new Date(timestamp) : new Date(); let d = timeS, hour = d.getHours(), minutes = d.getMinutes(), seconds = d.getSeconds(), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear(); if (month.length < 2)   month = '0' + month; if (day.length < 2) day = '0' + day; return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ; } ) ///
